html,
body,
#root {
    padding: 0;
    margin: 0;
    height: 100%;
    scroll-behavior: smooth;
}

a:hover {
    text-decoration: none;
}

.bd-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 300;
    font-size: 3rem;
}

.bd-title2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 300;
    font-size: 2rem;
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    max-width: 950px;
}

.navbar {
    max-width: 950px;
    margin: auto;
}

.navbar-brand img {
    width: 60px;
    position: fixed;
    top: 0px;
}

.content {
    padding-top: 70px;
    padding-bottom: 100px;
    max-height: calc(100% - 170px);
    overflow-y: auto;
}

.page-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    opacity: 0.9;
    background: #222222;
    z-index: 99;
}

.page-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 15px 0 0 0;
    background: #222222;
    opacity: 0.9;
    z-index: 99;
}

code {
    border-radius: 10px;
}

.search-blog {
    max-width: 420px;
}

.anchor-links {
    list-style: none;
    padding: 0;
}

.anchor {
    position: relative;
    height: 50px;
}

.card {
    color: #555;
}

.card-body {
  padding: 0.3em;
}

.card-img {
    object-fit: contain;
    max-height: 110px;
}

.card-title{
  font-size: 1em;
}

.card-text{
  font-size: 0.75em;
}

.tabelView {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.answer {
    background: lightgray;
    padding: 10px 5px;
    border-radius: 10px;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    opacity: 1 \9; /*just in case ie*/
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

.fade-in.one {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

textarea {
    width: 100%;
    min-height: 100px;
}

.textarea {
    position: relative;
}

.loading {
    position: absolute;
    top: 0px;
    left: -40px;
    color: #333;
}

.thumbnail {
    width: 104px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    height: 125px;
}

.large-text {
    font-size: 1.5em;
}

.navbar-toggler {
    position: fixed;
    border: 0;
    top: 20px;
}

.navbar-nav li:first-child {
    margin-left: -80px;
}

.step {
    background: #cccccc;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em; 
  }

@media only screen and (max-width: 991px) {
    .navbar-nav li:first-child {
        margin-left: 0;
    }
}
